<template>
	<v-container  class="dense" id="app" style="background:#222222;">
    <v-col>
      <Attitude class="indicator-bg" :size="150" :roll="roll" :pitch="pitch" />
      <Heading class="indicator-bg" :size="150" :heading="heading"/>
      <Altimeter class="indicator-bg" :size="150" :altitude="altitude" :pressure="pressure"/>
      <Airspeed class="indicator-bg" :size="150" :airspeed="airspeed"/>
      <!-- <Variometer class="indicator-bg" :size="150" :vario="vario"/> -->
    </v-col>
	</v-container>
</template>

<script>
import {Airspeed, Attitude, Altimeter, Heading, Variometer} from 'vue-flight-indicators'
import { mapGetters } from "vuex"

export default {
  props: {
    droneId: String
  },
  components: {
    Attitude,
    Heading,
    Variometer,
    Airspeed,
    Altimeter
  },
  data() {
    return {
      counter: 0,
      roll: 0,
      pitch: 0,
      heading: 0,
      vario: 0,
      airspeed: 0,
      altitude: 0,
      pressure: 0
    }
  },
  mounted() {
    setInterval(() => {
      // this.roll = 30*Math.sin(this.counter/10);
      // this.pitch = 50*Math.sin(this.counter/20);

      // this.heading = this.counter;

      this.vario = 2*Math.sin(this.counter/10);


      // this.altitude = 10*this.counter;
      this.pressure = 1000+3*Math.sin(this.counter/50);

      this.counter++;
    }, 100);
  },
  destroyed() {
  },
  computed: {
    ...mapGetters(["regionId", "regionInfo", "telemetry"]),
    rinfo() {
      return this.regionInfo
    },
    rid() {
      return this.regionId
    },
    tupdate() {
      return this.telemetry
    }
  },
  watch: {
    tupdate(newData, oldData) {
      if (this.droneId !== undefined) {
        try {
          // console.log('Telemetry updated', newData[this.droneId].lat)
          this.airspeed = newData[this.droneId]['airspeed']*1.94384;
          this.heading = 360-newData[this.droneId]['heading']
          this.pitch = 100*newData[this.droneId]['pitch']
          this.roll = 100*newData[this.droneId]['roll']
          this.altitude = newData[this.droneId]['alt']*2.23694;
        } catch {

        }
      }
    },
    rid(newRegion, oldRegion) {
      console.log("region id updated: "+newRegion)
    },
    rinfo(newRegionInfo, oldRgionInfo) {
      this.regionCenter = {lng:newRegionInfo.geometry.lng, lat:newRegionInfo.geometry.lat}
      if (newRegionInfo.region_id !== oldRgionInfo.region_id) {
        // this.map.setCenter(this.regionCenter)
      }
    }
  },

  methods: {
  }
}
</script>
